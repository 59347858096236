import { API } from "@project/common"

export interface IReturnWorkDetailsMaster {
  count?: number
  data?: any
}

interface IWorkDetailsMasterParam {
  page?: string
  pageSize?: string
}

export const fetchWorkDetailsMaster = async ({
  queryKey,
}): Promise<IReturnWorkDetailsMaster> => {
  const params = {} as IWorkDetailsMasterParam
  params.page = queryKey[1] || 1
  params.pageSize = queryKey[2] || 20
  return API.get(`/work`, { params })
}

export const createWorkDetailMaster = async (values): Promise<any> => {
  return API.post(`/work`, values)
}

export const getOneWorkDetailsMaster = async (id: number) => {
  return API.get(`/work/${id}`)
}

export const updateWorkDetailsMaster = async (values) => {
  const id = values.id
  delete values.id
  return API.put(`/work/${id}`, values)
}
export const fetchWorkCategory = async (): Promise<any> => {
  return API.get(`/work-category`)
}
export const deleteWorkDetailsByID = async (id: number): Promise<any> => {
  return API.delete(`/work/${id}`)
}
