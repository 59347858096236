import { getLanguage } from "@project/common"
import { useQuery } from "react-query"
import {
  fetchAllProvisionalCity,
  fetchAllShiftMaster,
  fetchInfinityUsers,
  fetchInstructors,
  getAllMealMaster,
  getConfirmedBillingFacilities,
  getDateInfoDetail,
  getOccupations,
  getOneInstructor,
  getOneUser,
  getQualification,
  getUserActiveContract,
} from "../services"
import { fetchAllConstulationSupportOffice } from "../services/consultationSupportOffice"
import { fetchWorkDetailsMaster } from "../services/workDetailsMaster"

export const useFetchOccupation = () => {
  const lng = getLanguage()
  const { data: occupations } = useQuery("occupations", getOccupations, {
    select: (response) => {
      return response?.data?.map((val) => ({
        label: lng === "en" ? val?.occupation_name_eng : val?.occupation_name,
        value: val?.id,
        type: val?.type,
        is_manager: val?.is_manager,
        title: val?.occupation_name_eng,
      }))
    },

    refetchOnWindowFocus: false,
  })
  return { occupations }
}
export const useFetchQualifications = () => {
  const lng = getLanguage()
  const { data: qualifications } = useQuery({
    queryKey: ["qualifications"],
    queryFn: () => getQualification(),
    select: (response) => {
      return {
        ...response,
        data: response?.data?.map((val) => ({
          ...val?.data,
          label:
            lng === "en"
              ? val?.qualification_name_eng
              : val?.qualification_name,
          value: val?.id,
        })),
      }
    },

    refetchOnWindowFocus: false,
  })

  return { qualifications }
}

export const useFetchMealMaster = ({
  page,
  pageSize,
  facilityIds = "",
  enabled = true,
}) => {
  const {
    data: mealData,
    isLoading,
    isFetching,
  } = useQuery({
    queryKey: ["meal-master", page],
    queryFn: () => getAllMealMaster({ page, pageSize, facilityIds }),
    select: (response) => {
      return {
        ...response,
        data: response?.data?.map((val) => ({
          ...val,
          label: val?.name,
          value: val?.id,
        })),
      }
    },
    refetchOnWindowFocus: false,
    keepPreviousData: false,
    retry: 1,
    enabled,
  })
  return { mealData, isFetching, isLoading }
}
export const useFetchConsultationSupportOffice = ({ page }) => {
  const {
    data: consultationSupportOffice,
    isLoading,
    isFetching,
  } = useQuery({
    queryKey: ["consultaion-support-office", page],
    queryFn: () => fetchAllConstulationSupportOffice({ page }),
    select: (response) => {
      return {
        data: response?.data?.map((val) => ({
          label: val?.consultation_support_name,
          value: val?.id,
        })),
      }
    },
    retry: 1,
  })
  return { consultationSupportOffice, isFetching, isLoading }
}
export const useFetchProvisionCity = ({ queryKey }) => {
  const {
    data: provisionCity,
    isLoading,
    isFetching,
  } = useQuery({
    queryKey: ["provision-city", queryKey],
    queryFn: () => fetchAllProvisionalCity(),
    select: (response) => {
      const data = [{ provision_city_name: "--", id: null }]
      const allData = [...data, ...response?.data]
      return {
        data: allData?.map((val) => ({
          label: val?.provision_city_name,
          value: val?.id,
        })),
      }
    },
    retry: 1,
  })
  return { provisionCity, isFetching, isLoading }
}
export const useFetchAllUsers = ({
  page,
  pageSize,
  setInitialValue,
  facilityIds,
}: {
  page: number
  pageSize: string
  setInitialValue?: boolean
  facilityIds?: string
}) => {
  const {
    data: userData,
    isLoading,
    isFetching,
  } = useQuery({
    queryKey: ["fetch-all-users", page, pageSize, facilityIds],
    queryFn: () => fetchInfinityUsers({ page, pageSize, facilityIds }),
    refetchOnWindowFocus: false,
    cacheTime: 2 * (60 * 1000),
    select: (response) => {
      const data = setInitialValue
        ? [
            {
              value: null,
              label: "---",
              certificate: "",
              use_service_id: "",
              furigana: "",
              failure_types: "",
              start_date: "",
              assessment_count: 0,
              facility_id: 0,
              service_start_date: null,
              id: null,
            },
          ]
        : []
      response?.data?.map((val) => {
        data?.push({
          id: val?.id,
          certificate: val?.certificate_number,
          use_service_id: val?.service_type || "",
          facility_id: val?.facility_id || "",
          furigana: val?.furigana,
          label: val?.username,
          assessment_count: val?.assessment_count || 0,
          value: `${val?.id}`,
          failure_types: val?.failure_types,
          start_date:
            val?.user_certificate?.start_date || val?.created_datetime,
          service_start_date: val?.service_start_date,
        })
      })
      return data
    },
    retry: 1,
  })
  return { userData, isFetching, isLoading }
}

export const useFetchAllStaff = ({
  page,
  pageSize,
  enabled = true,
  facility,
  date_staff_is_active = "",
}: {
  page?: number
  pageSize: "Infinity"
  enabled?: boolean
  facility?: string
  date_staff_is_active?: string
}) => {
  const {
    data: staffData,
    isLoading,
    isFetching,
  } = useQuery({
    queryKey: ["all-staff", facility],
    queryFn: () =>
      fetchInstructors({ page, pageSize, facility, date_staff_is_active }),
    select: (response) => {
      return response?.data?.map((val) => ({
        ...val,
        label: val?.staff_name,
        value: val?.id,
        staff_name_furiganaame: val?.staff_name_furiganaame || "",
        furigana: val?.staff_name_furiganaame || "",
      }))
    },
    retry: 1,
    enabled: enabled,
  })
  return { staffData, isFetching, isLoading }
}
export const useFetchOneStaff = (staff_id: any) => {
  const {
    data: staffDetail,
    isLoading,
    isFetching,
  } = useQuery({
    queryKey: ["all-staff", staff_id],
    queryFn: () => getOneInstructor(staff_id),
    refetchOnWindowFocus: false,
    retry: 1,
    enabled: !!staff_id,
  })
  return { staffDetail, isFetching, isLoading }
}
export const useFetchAllShiftMaster = ({
  page,
  pageSize,
  facilityIds,
  addInitialOption,
  targetIds,
}: any) => {
  const { data: shiftData, isLoading } = useQuery<any, Error>({
    queryKey: ["infinity-shift_master_list"],
    queryFn: () =>
      fetchAllShiftMaster({
        page,
        pageSize,
        facilityIds,
        targetIds,
      }),
    keepPreviousData: true,
    refetchOnWindowFocus: false,
    select: (response) => {
      const options = addInitialOption
        ? [
            {
              label: "---",
              value: null,
              attendance_end_time: "",
              attendance_end_time2: "",
              attendance_rest_minits: "",
              attendance_rest_minits2: "",
              attendance_start_time: "",
              attendance_start_time2: "",
              attendance_shift_facilities: [],
            },
          ]
        : []
      response?.data?.map((v) => {
        options?.push({
          label: v?.attendance_shift_name,
          value: `${v?.id}`,
          attendance_end_time: v?.attendance_end_time,
          attendance_end_time2: v?.attendance_end_time_2,
          attendance_rest_minits: v?.attendance_rest_minits,
          attendance_rest_minits2: v?.attendance_rest_minits_2,
          attendance_start_time: v?.attendance_start_time,
          attendance_start_time2: v?.attendance_start_time_2,
          attendance_shift_facilities: v?.attendance_shift_facilities,
        })
      })
      return {
        data: response?.data,
        options,
      }
    },
  })
  return { shiftData, isLoading }
}

export const useFetUsersContractFacility = (
  user_id: any,
  setInitialValue?: boolean,
  extra?: any,
) => {
  const {
    data: userContractData,
    isLoading,
    isFetching,
  } = useQuery({
    queryKey: ["user-contract", user_id, extra],
    queryFn: () => getUserActiveContract(user_id, extra),
    refetchOnWindowFocus: false,
    select: (response) => {
      const data = setInitialValue
        ? [
            {
              value: null,
              label: "---",
            },
          ]
        : []
      response?.data?.map((val) => {
        data?.push({
          label: val?.facility?.facility_name,
          value: `${val?.facility_id || val?.facility?.id}`,
        })
      })
      return data
    },
    retry: 1,
    keepPreviousData: true,
  })
  return { userContractData, isFetching, isLoading: isLoading || isFetching }
}
export const useFetchOneUser = (user_id: any) => {
  return useQuery({
    queryKey: ["one-user", user_id],
    queryFn: () => getOneUser(user_id),
    select: (res: any) => res || {},
    refetchOnWindowFocus: false,
  })
}

export const useFetchFacilitiesWithConfirmedBilling = (params: {
  year: number
  month: number
  facilityIds: any
}) => {
  const { isLoading, data: facilitiesWithConfirmedBilling } = useQuery({
    queryKey: ["get-facilities-with-confirmed-billing", params],
    queryFn: () =>
      getConfirmedBillingFacilities({
        year: params?.year,
        month: params?.month,
        facilityIds: params?.facilityIds,
      }),
    enabled: !!params?.facilityIds,
  })
  return { facilitiesWithConfirmedBilling, isLoading }
}

export const useFetchWorkDetailsMaster = ({
  page,
  pageSize,
}: {
  page?: number
  pageSize?: number | "Infinity"
}) => {
  const {
    isLoading,
    isFetching,
    data: workDetailsMasterData,
  } = useQuery(["fetchWorkDetailsMaster", page, pageSize], {
    queryFn: fetchWorkDetailsMaster,
    keepPreviousData: true,
    refetchOnWindowFocus: false,
    cacheTime: 0,
    retry: 1,
  })
  return {
    isLoading,
    isFetching,
    workDetailsMasterData,
  }
}
export const useGetDateInfoDetail = (queries: {
  date?: string
  facilityIds: number
  year: number
  month: number
}) => {
  const {
    data: dateInfo,
    refetch,
    isLoading: dateInfoLoading,
    isFetching: dateInfoFetching,
  } = useQuery<any>(
    ["get-date-info-detail", queries],
    () => getDateInfoDetail(queries),
    {
      keepPreviousData: false,
      refetchOnWindowFocus: false,
      cacheTime: 0,
      retry: 1,
      select: ({ data }) => data,
    },
  )
  return {
    dateInfo,
    refetch,
    dateInfoLoading,
    dateInfoFetching,
  }
}
