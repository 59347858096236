import { API } from "@project/common"
import { PAGE_SIZE_20 } from "../constants"

export interface IReturnConsultationSupportOffice {
  count?: number
  data?: any
}

interface IConsultationSupportOfficeParam {
  page?: string
  pageSize?: string
}

export const fetchConsultationSupportOffice = async ({
  queryKey,
}): Promise<IReturnConsultationSupportOffice> => {
  const params = {} as IConsultationSupportOfficeParam

  params.page = queryKey[1]
  params.pageSize = String(PAGE_SIZE_20)
  return API.get(`/consultation-support`, {
    params,
  })
}

export const fetchAllConstulationSupportOffice = async ({
  page,
}: {
  page?: string
}): Promise<IReturnConsultationSupportOffice> => {
  const params = {} as IConsultationSupportOfficeParam
  params.pageSize = "Infinity"
  params.page = page
  return API.get(`/consultation-support`, {
    params,
  })
}

export const getConsultationSupportOffice = async (id: number) => {
  return API.get(`/consultation-support/${id}`)
}

export const createConsultationSupportOffice = async (values): Promise<any> => {
  const rslt = {
    ...values,
    main_target_disability: values?.main_target_disability
      ? '"' + values?.main_target_disability?.join('", "') + '"'
      : undefined,
  }

  return API.post(`/consultation-support`, rslt)
}

export const updateConsultationSupportOffice = async (values) => {
  const rslt = {
    ...values,
    main_target_disability: values?.main_target_disability
      ? '"' + values?.main_target_disability?.join('", "') + '"'
      : undefined,
  }
  const id = rslt.id
  delete rslt.id

  return API.put(`/consultation-support/${id}`, rslt)
}

export const deleteConsultationSupportOffice = async (id) => {
  return API.delete(`/consultation-support/${id}`)
}
